import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import languageEN from '../language/en/translation.json';
import languageDE from '../language/de/translation.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {translation: languageEN},
      de:  {translation: languageDE},
    },

    lng: "en",
    fallbackLng: "en", // use en if detected lng is not available

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
});
export default i18n;
import {api, headers} from './base'; 
import constants from '../config/constants';

export const apiPost = (obj, apiName, isFormData = false, authHeaders ) => {
      
    obj = getHeaders(obj, isFormData, authHeaders);
  
    return api.post( apiName, obj.values, obj.apiHeader )
    .then(function onSuccess(response) {
        return {status: response.status, data:response.data};
    })
    .catch(function onError(error) {
        if(error.response == null) {
            return Promise.reject('Something went wrong, please try again!');
        }       
        return Promise.reject(error.response.data.error || 'Something went wrong, please try again!');
    });
}

export const apiGet = (obj, apiName, authHeaders ) => {
      
    obj = getHeaders(obj, false, authHeaders);
  
    return api.get( apiName, obj.values, obj.apiHeader )
    .then(function onSuccess(response) {
        return {status: response.status, data:response.data};
    })
    .catch(function onError(error) {
        if(error.response == null) {
            return Promise.reject('Something went wrong, please try again!');
        }       
        return Promise.reject(error.response.data.error || 'Something went wrong, please try again!');
    });
}

export const apiDelete = (obj, apiName, authHeaders ) => {
      
    obj = getHeaders(obj, false, authHeaders);
  
    return api.delete( apiName, obj.values, obj.apiHeader )
    .then(function onSuccess(response) {
        return {status: response.status, data:response.data};
    })
    .catch(function onError(error) {
        if(error.response == null) {
            return Promise.reject('Something went wrong, please try again!');
        }       
        return Promise.reject(error.response.data.error || 'Something went wrong, please try again!');
    });
}

function getHeaders(obj, isFormData = false, authHeaders) {

    if (isFormData) {
        obj.append('version', constants.version);
        obj.append('device', constants.device);       
    }
    else {
        obj = {...obj, version: constants.version, device: constants.device }  
    }

    if (authHeaders === 'authHeaders') {
        headers.headers = {...headers.headers, Authorization: 'Bearer '+ localStorage.getItem('accessToken')}
    }

    return {values: obj, apiHeader: headers}
}
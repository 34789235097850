import React from "react";
import { Button } from 'antd';

export default function ButtonBlue(props) {
    return (
        
          <Button
            type={ props.type } 
            htmlType={ props.htmlType }   
            className="login-form-button medium-text blue-button" 
            loading = { props.loading ? props.loading : false }
            onClick = { props.onClick }
          >
            {props.text}
          </Button>
      
    );
}
import React from "react";
import { withRouter } from "react-router-dom";
import HelmetText from "../../components/HelmetText";
import { Row, Col } from 'antd';

import InnerBanner from "../../components/InnerBanner";


function Contactus() {

	return (
		<>
			<HelmetText title="Contact Us" /> 
			<InnerBanner />
			<div className="contact-us-page  right-cover-bg">
				<div className=" full-container">
					<div className="left-cover-icons  sectionpadding ">
						<Row>
							<Col lg={12} sm={24} xs={24} >
								<h2 className="main-title">Contact Us</h2>
								<div className="contact-detail">
									<h4>Address</h4>
									<p className="contact-text">OU721, Esplanade, Rasulgarh, Bhubaneswar, Odisha</p>
								</div>
								<ul className="email-callus-row">
									<li className="email-callus-col">
										<div className="contact-detail">
											<h4>Email</h4>
											<a href="mailto:contact@mohrisa.com" className="contact-text">contact@mohrisa.com</a>
										</div>
									</li>
									<li className="email-callus-col">
										<div className="contact-detail">
											<h4>Call Us</h4>
											<a href="tel:+919556923333" className="contact-text">+91 9556923333</a>
										</div>
									</li>
								</ul>
								<div className="follow-detail">
									<div className="contact-detail">
										<h4>Follow Us</h4>
									</div>
									<ul className="social-media-row">
										<li className="social-media-col">
											<a href=""><i class="fa fa-facebook" aria-hidden="true"></i></a>
										</li>
										<li className="social-media-col">
											<a href=""><i class="fa fa-linkedin" aria-hidden="true"></i></a>
										</li>
									</ul>
								</div>
							</Col>
							<Col lg={12} sm={24} xs={24}>
								<div className="form-detail-area">
									<h2 className="main-title">Inquiry Form</h2>
									<ul className="form-detail-row">
										<li className="form-detail-col">
											<div class="form-group form_box">
												<input type="text" placeholder="First Name" name="txtname" maxlength="200" minlength="2" class="form-control required" required="" />
											</div>
										</li>
										<li className="form-detail-col">
											<div class="form-group form_box">
												<input type="text" placeholder="Last Name" name="txtname" maxlength="200" minlength="2" class="form-control required" required="" />
											</div>
										</li>
										<li className="form-detail-col">
											<div class="form-group form_box">
												<input type="text" placeholder="Email Id" name="txtname" maxlength="200" minlength="2" class="form-control required" required="" />
											</div>
										</li>
										<li className="form-detail-col">
											<div class="form-group form_box">
												<input type="text" placeholder="Phone Number" name="txtname" maxlength="200" minlength="2" class="form-control required" required="" />
											</div>
										</li>
										<li className="form-detail-col full-col">
											<div class="form-group form_box">
												<textarea class="form-control form_textarea" name="txtmessage" placeholder="Comment" maxlength="500" required=""></textarea>
											</div>
											<button className="main-button">Submit</button>
										</li>
									</ul>
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</div>
			<div className="full-container">
				<div className="map sectionpadding sectionpadding-boottom">
					<iframe title="map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3743.3110362943708!2d85.88601394641877!3d20.245931226042217!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9f4e22661c9945b2!2sMohirsa%20Techologies!5e0!3m2!1sen!2sin!4v1633017777208!5m2!1sen!2sin" allowfullscreen="" loading="lazy"></iframe>
				</div>
			</div>
		</>
	);
}

export default withRouter(Contactus);
import React from "react";
import ROUTES from "../route/routes";
import { Link } from "react-router-dom";
import { Image } from 'antd';
import logo from '../assets/images/logo.png';

export default function Logo(props) {
    return (
        <div className={"logo-container "+(props.class? props.class : '')}>
            <Link to={ROUTES.home.path} >
                <Image
                    src={logo}
                    alt={process.env.REACT_APP_NAME} 
                    className="white-logo-img"
                    preview={false}
                    width={props.width}
                />
            </Link>      
        </div> 
    );
}
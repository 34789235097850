import React, { useEffect, useState } from 'react';
import ROUTES from './routes';
import { Route, Switch, withRouter } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { SET_MESSAGE, SET_USER } from "../actions/types";
import { getProfile } from "../services/auth.service";
import { LoadingOutlined } from '@ant-design/icons';
import { getRouteObj } from '../helpers/getRouteObj';

function RenderRoutes(props) {

	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const history = useHistory();
	const location = useLocation()

	useEffect(() => {

		let accessToken = localStorage.getItem("accessToken");
		let logged_in = (!(accessToken === null || accessToken.length === 0));
		
		let route = getRouteObj(location.pathname);     
		
		if (!route) {
			console.log("Not found");
			// this.props.history.push(ROUTES.not_found.path);
		}
		else if (route && route.type === 'authenticated' && !logged_in) {
			history.push({
				pathname: ROUTES.login.path,
			});	
		}
		else if (route && route.type === 'authenticated') {
			dispatch(getProfile())
			.then((obj) => {
	
				dispatch({
					type: SET_USER,
					payload: { user: obj.data.detail },
				})
	
				if (obj.status === 203) {				
					dispatch({
						type: SET_MESSAGE,
						payload: { message: obj.data.message, messageType: "error" },
					});
	
					history.push({
						pathname: ROUTES.login.path,
					});			
				}
	
				setLoading( true );         
			})
			.catch((error) => {
				setLoading( true );  
				dispatch({
					type: SET_MESSAGE,
					payload: { message: error, messageType: "error" },
				});
			});
		}
		else if (route && route.type === 'guest' && logged_in) {
			history.push({
				pathname: ROUTES.home.path,
			});   
		}
		else {
			setLoading (true) ;
		}
		
	}, []);

	return (
		loading ?
			<Switch>
				{ Object.keys(ROUTES).map((r,k) =>   
					ROUTES[r].layout === props.layout ?
					<Route 
						exact={ROUTES[r].exact} 
						path={ROUTES[r].path} 
						component = {ROUTES[r].component} 
						key = {k}
					/>  
					: null
				) 
				} 
			</Switch>
		:
			<div className="loading-wrapper"><LoadingOutlined style={{color:'#36ade3', fontSize: 50}}/></div>
	);
}


export default withRouter(RenderRoutes);
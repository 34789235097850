import React from "react";
import { withRouter } from "react-router-dom";
import HelmetText from "../../components/HelmetText";
import { Row, Col } from 'antd';
import Servicesimg from "../../assets/images/services/services_box.jpg";
import services01 from "../../assets/images/services/services_01.jpg";
import services02 from "../../assets/images/services/services_02.jpg";
import services03 from "../../assets/images/services/services_03.jpg";
import services04 from "../../assets/images/services/services_04.jpg";
import InnerBanner from "../../components/InnerBanner";

function Services() {

	return (
		<>
			<HelmetText title="Services" />
			<InnerBanner/>
			<div className=" common-section right-cover-bg">
				<div className=" full-container">
					<div className="left-cover-icons  sectionpadding ">
						<Row>
							<Col lg={11}>
								<div className="common-section-left">
									<h2 className="main-sub-title">SAP Security Assessment & Authorization Redesign</h2>
									<h4 className="headlinesfonts">An optimal solution for businesses on a global level - SAP. To cater to the ever-changing regulatory norms and offer dynamic solutions to plan, design and execute your SAP’s security and authorization system.  </h4>
								</div>
							</Col>
							<Col lg={13}>
								<div className="common-section-right">
									<div className="common-detail">
								     	<p>At Mohrisa Technologies, we have a comprehensive solution which has been thoroughly tested to cater to any SAP security solution. With us, you can be assured of your SAP system to be in a well-organized structure and security.</p>
									</div>
									<div className="common-img">
									<img src={Servicesimg} alt="SAP Security Assessment & Authorization Redesign"/>
								</div>
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</div>
			<div className="services-area sectionpadding sectionpadding-boottom">
			     <div className="full-container">
					    <div className="services-row">
						   <div className="services-col">
						       <div className="services-box">
							      <img src={services01} className="services-img" alt="SAP Security Build"/>
								<div className="services-details"> 
								    <h3>SAP Security Build</h3>
									<p>We aim to achieve a creative SAP Security design. Our team of highly trained experts ensure the authorisation design is 
									Segregation of Duty (SoD) free, complies with the requirements and are perfectly coordinated with the business processes.</p>
								</div>
							   </div>
						   </div>
						   <div className="services-col">
						       <div className="services-box">
							      <img src={services02} className="services-img" alt="SAP Security Redesign"/>
								<div className="services-details"> 
								    <h3>SAP Security Redesign</h3>
									<p>Our experts do a complete and thorough check of the existing authorisation structure and further offer the best option for the redesign of the roles. Also, the ways to better compliance, reduce segregation of duties and overall amplify security efficacy.</p>
								</div>
							   </div>
						   </div>
						   <div className="services-col">
						       <div className="services-box">
							      <img src={services03} className="services-img" alt="SAP Upgradations & Migrations"/>
								<div className="services-details"> 
								    <h3>SAP Upgradations & Migrations</h3>
									<p>The integrated SAP up-gradation and migration services will safeguard your security and further upgrade your SAP
									 system for continuous and consistent operation. Our proprietary upgrade templates are easily accessible.</p>
								</div>
							   </div>
						   </div>
						   <div className="services-col">
						       <div className="services-box">
							      <img src={services04} className="services-img" alt="SAP Security Managed Services"/>
								<div className="services-details"> 
								    <h3>SAP Security Managed Services</h3>
									<p>Consider us to be your trusted SAP security partner for your business. Our complete set of services from ad-hoc support, role administration, audit reporting to user administration is easily accessible.</p>
								</div>
							   </div>
						   </div>
						</div>
					</div>
			</div>
		</>
	);
}

export default withRouter(Services);
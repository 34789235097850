import {
    SET_USER
} from "../actions/types";
  
const initialState = {};

const membershipReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER:         
            state = {
                ...state,
                ...action.payload.user,
            };
            break;
        default:      
    }
    return state;
};

export default membershipReducer;    
import React from "react";
import Aboutusbanner from '../assets/images/inner_banner/aboutus.jpg';
import Servicesbanner from '../assets/images/inner_banner/services.jpg';
import Careerbanner from '../assets/images/inner_banner/career.jpg';
import Contactusbanner from '../assets/images/inner_banner/contactus.jpg';
import Outclientbanner from '../assets/images/inner_banner/outclient.jpg';
import Productbanner from '../assets/images/inner_banner/product.jpg';
import Zapqbanner from '../assets/images/inner_banner/zapqbanner.jpg';
import Privacypolicy from '../assets/images/inner_banner/privacypolicy.jpg';

import ROUTES from '../route/routes';
import { useLocation } from 'react-router-dom';


export default function InnerBanner() {
    const location = useLocation()
    const pathname = location.pathname;
    return (
        <div className="inner-banner-area right-cover-bg">
            <div className="full-container">
                {(() => {
                    if (pathname === ROUTES.aboutus.path) {
                        return (
                            <div className="inner-banner-box">
                                <img src={Aboutusbanner} className="banner-img" alt="About Banner" />
                                <div className="inner-banner-detail">
                                    <h1>Know</h1>
                                    <h2><span>About</span>  Company</h2>
                                </div>
                            </div>
                        )
                    } else if (pathname === ROUTES.services.path) {
                        return (
                            <div className="inner-banner-box">
                                <img src={Servicesbanner} className="banner-img" alt="Our Services" />
                                <div className="inner-banner-detail">
                                    <h1>Our </h1>
                                    <h2><span>Services</span> </h2>
                                </div>
                            </div>
                        )
                    } else if (pathname === ROUTES.products.path) {
                        return (
                            <div className="inner-banner-box">
                                <img src={Productbanner} className="banner-img" alt="Our Products" />
                                <div className="inner-banner-detail">
                                    <h1>Our</h1>
                                    <h2><span> My Kommunity</span></h2>
                                </div>
                            </div>
                        )
                    }else if (pathname === ROUTES.client.path) {
                        return (
                            <div className="inner-banner-box">
                                <img src={Outclientbanner} className="banner-img" alt="Our Client" />
                                <div className="inner-banner-detail">
                                    <h1>Our </h1>
                                    <h2><span>Clients</span> </h2>
                                </div>
                            </div>
                        )
                    }
                    else if (pathname === ROUTES.contactus.path) {
                        return (
                            <div className="inner-banner-box">
                                <img src={Contactusbanner} className="banner-img" alt="Contact Us" />
                                <div className="inner-banner-detail">
                                    <h1>Get in  </h1>
                                    <h2><span>Touch</span> </h2>
                                </div>
                            </div>
                        )
                    }else if (pathname === ROUTES.career.path) {
                        return (
                            <div className="inner-banner-box">
                                <img src={Careerbanner} className="banner-img" alt="Career" />
                                <div className="inner-banner-detail">
                                    <h1>Join </h1>
                                    <h2><span>With us</span> </h2>
                                </div>
                            </div>
                        )
                    }
                    else if (pathname === ROUTES.zapq.path) {
                        return (
                            <div className="inner-banner-box">
                                <img src={Zapqbanner} className="banner-img" alt="Zapq banner" />
                                <div className="inner-banner-detail">
                                    <h1>Our </h1> 
                                    <h2><span>ZAPQ Swift shopping</span> </h2>
                                </div>
                            </div>
                        )
                    }
                    else if (pathname === ROUTES.privacypolicy.path) {
                        return (
                            <div className="inner-banner-box">
                                <img src={Privacypolicy} className="banner-img" alt="pPrivacy Policy" />
                                <div className="inner-banner-detail">
                                    <h1>Our </h1> 
                                    <h2><span>Privacy Policy</span> </h2>
                                </div>
                            </div>
                        )
                    }
                })()}
            </div>
        </div>
    );
}

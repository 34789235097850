import Dashboard from '../view/dashboard/Dashboard';
import Home from '../view/dashboard/Home';
import Aboutus from '../view/subpages/Aboutus';
import Services from '../view/subpages/Services';
import Products from '../view/subpages/Products';
import Client from '../view/subpages/Client';
import Contactus from '../view/subpages/Contactus';
import Career from '../view/subpages/Career';
import Zapq from '../view/subpages/Zapq';
import Privacypolicy from '../view/subpages/Privacypolicy';

// import NotFound from '../views/NotFound';


const ROUTES = { 
    'home':{
        path: "/",
        component:Home,
        type: 'guest',
        layout: 'default',
        exact: true
    },
    'aboutus':{
        path: "/aboutus",
        component: Aboutus,
        type: 'guest',
        layout: 'default',
        exact: true
    },
    'services':{
        path: "/services",
        component: Services,
        type: 'guest',
        layout: 'default',
        exact: true
    },
    'products':{
        path: "/products",
        component: Products,
        type: 'guest',
        layout: 'default',
        exact: true
    },
    'client':{
        path: "/clients",
        component: Client,
        type: 'guest',
        layout: 'default',
        exact: true
    },
    'contactus':{
        path: "/contactus",
        component: Contactus,
        type: 'guest',
        layout: 'default',
        exact: true
    },
    'career':{
        path: "/career",
        component: Career,
        type: 'guest',
        layout: 'default',
        exact: true
    },
    // 'login':{
    //     path: "/login",
    //     component: Login,
    //     type: 'guest',
    //     layout: 'default',
    //     exact: true
    // },
    'dashboard':{
        path: "/user/dashboard",
        component: Dashboard,
        type: 'authenticated',
        layout: 'dashboard',
        exact: true
    },
     'zapq':{
        path: "/zapq",
        component: Zapq,
        type: 'guest',
        layout: 'default',
        exact: true
    },
    'privacypolicy':{
        path: "/privacy-policy",
        component: Privacypolicy,
        type: 'guest',
        layout: 'default',
        exact: true
    },
    
};



export default ROUTES;
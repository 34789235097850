import React from "react";
import { withRouter } from "react-router-dom";
import HelmetText from "../../components/HelmetText";
import { Row, Col } from 'antd';
import Mykommunity from "../../assets/images/products/mykommunity.jpg";
import products01 from "../../assets/images/products/product_01.jpg";
import products02 from "../../assets/images/products/product_02.jpg";
import products03 from "../../assets/images/products/product_03.jpg";

import InnerBanner from "../../components/InnerBanner";


function Products() {

	return (
		<>
			<HelmetText title="My Kommunity" />
			<InnerBanner/>
			<div className=" common-section right-cover-bg">
				<div className=" full-container">
					<div className="left-cover-icons  sectionpadding ">
						<Row>
							<Col lg={11}>
								<div className="common-section-left">
									<h2 className="main-title">My Kommunity</h2>
									<h4 className="headlinesfonts">We offer state-of-the-art technology to keep your routine life more comfortable, safe and secure. </h4>
									<h2 className="main-sub-title">Resident Management</h2>
									<p>Keep your mind at ease, while we ensure the complete security of your loved ones at home. </p>
								</div>
							</Col>
							<Col lg={13}>
								<div className="common-section-right">
									<div className="common-img">
									<img src={Mykommunity} alt="Mykommunity"/>
								</div>
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</div>
			<div className="services-area sectionpadding sectionpadding-boottom">
			     <div className="full-container">
					    <div className="services-row">
						   <div className="services-col">
						       <div className="services-box">
							      <img src={products01} className="services-img" alt="Visitor Management System"/>
								<div className="services-details"> 
								    <h3>Visitor Management System</h3>
									<ul className="dotlist">
										<li>Access and validate visitors from your mobile device.</li>
										<li>Get instant notification on visitors.</li>
										<li>Easy and quick help during emergencies from trained security personnel.</li>
										<li>Regular attendance of visiting staff such as maid, driver, nanny and cook.</li>
										<li>Prior approval of expected visitors.</li>
									</ul>
								</div>
							   </div>
						   </div>
						   <div className="services-col">
						       <div className="services-box">
							      <img src={products02} className="services-img" alt="Apartment Owners Association - Management & Committee"/>
								  <div className="services-details"> 
								    <h3>Apartment Owners Association - Management & Committee</h3>
									<p>Discover the true sense of security for your community.</p>
									<ul className="dotlist">
										<li>Ease of monitoring the security from anywhere and at any time.</li>
										<li>Detailed visibility into security processes and effective use of costs.</li>
										<li>Proper surveillance with proper data capture at the security office.</li>
									</ul>
								</div>
							   </div>
						   </div>
						   <div className="services-col">
						       <div className="services-box">
							      <img src={products03} className="services-img" alt="Security Guards"/>
								  <div className="services-details"> 
								    <h3>Security Guards</h3>
									<p>Get equipped with the best technology to secure your safety.</p>
									<ul className="dotlist">
										<li>Equip all processes of registration through phones or tablets.</li>
										<li>Ability to double-check and not allow imposters to enter the apartments.</li>
										<li>Capability to verify and check the contact details of visitors, capture pictures and monitor their stay timeline.</li>
										<li>Quick check-ins at every instance.</li>
									</ul>
								</div>
							   </div>
						   </div>
						 
						</div>
					</div>
			</div>
		</>
	);
}

export default withRouter(Products);
import React, { useState } from 'react';
import { withRouter  } from "react-router-dom";
import HelmetText from "../../components/HelmetText";
import { Row, Col, Modal } from 'antd';
import Careermain from "../../assets/images/career.jpg";

import InnerBanner from "../../components/InnerBanner";


function Career() {
	const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => {
        setIsModalVisible(true);
      };
    
    //   const handleOk = () => {
    //     setIsModalVisible(false);
    //   };
    
      const handleCancel = () => {
        setIsModalVisible(false);
      };
	return (
		<>
			<HelmetText title="Career" />
			<InnerBanner/>
			<div className="career-top-area common-section right-cover-bg">
				<div className=" full-container">
					<div className="left-cover-icons  sectionpadding ">
						<Row>
							<Col lg={11}>
								<div className="common-section-left">
									<h2 className="main-title">Career with us</h2>
									<h4 className="headlinesfonts">Be your vibrant self with us </h4>
									<div className="career-detail-box">
									<p>At Mohrisa Technologies, you get to explore the various opportunities life gives you while also working to develop a stable solution for our customers. All your ideas are valued,
									 and you will be given plenty of space to generously expand your skills and functions.  Join hands with us to work on some of the most important security concerns of our digital world. </p>
								   </div>
								</div>
							</Col>
							<Col lg={13}>
								<div className="common-section-right">
									<div className="common-img">
									<img src={Careermain} alt="Carer"/>
								</div>
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</div>
			<div className="job-openings-area sectionpadding sectionpadding-boottom">
			     <div className="full-container">
					<h2 className="main-title">Job Openings</h2>
					 <h3 className="headlinesfonts">For current job openings at our company, we request you to please email us with your updated CV at <a href="mailto:careers@mohrisa.com">careers@mohrisa.com</a></h3>
					{/* <ul className="job-openings-row">
						<li className="job-openings-col">
							<div className="job-openings-box">
								<h3>Business Development Executive</h3>
								<p>We are looking for Business Development Executive with experience in the same domain (preferably IT).</p>
								<button onClick={showModal} className="main-button">Apply</button>
							</div>
						</li>
						<li className="job-openings-col">
							<div className="job-openings-box" >
								<h3>Business Development Executive</h3>
								<p>We are looking for Business Development Executive with experience in the same domain (preferably IT).</p>
								<button onClick={showModal} className="main-button">Apply</button>
							</div>
						</li>
						<li className="job-openings-col">
							<div className="job-openings-box">
								<h3>Business Development Executive</h3>
								<p>We are looking for Business Development Executive with experience in the same domain (preferably IT).</p>
								<button onClick={showModal} className="main-button">Apply</button>
							</div>
						</li>
						<li className="job-openings-col">
							<div className="job-openings-box">
								<h3>Business Development Executive</h3>
								<p>We are looking for Business Development Executive with experience in the same domain (preferably IT).</p>
								<button onClick={showModal} className="main-button">Apply</button>
							</div>
						</li>
						<li className="job-openings-col">
							<div className="job-openings-box">
								<h3>Business Development Executive</h3>
								<p>We are looking for Business Development Executive with experience in the same domain (preferably IT).</p>
								<button onClick={showModal} className="main-button">Apply</button>
							</div>
						</li>
						<li className="job-openings-col">
							<div className="job-openings-box">
								<h3>Business Development Executive</h3>
								<p>We are looking for Business Development Executive with experience in the same domain (preferably IT).</p>
								<button onClick={showModal} className="main-button">Apply</button>
							</div>
						</li>
						<li className="job-openings-col">
							<div className="job-openings-box">
								<h3>Business Development Executive</h3>
								<p>We are looking for Business Development Executive with experience in the same domain (preferably IT).</p>
								<button onClick={showModal} className="main-button">Apply</button>
							</div>
						</li>
						<li className="job-openings-col">
							<div className="job-openings-box">
								<h3>Business Development Executive</h3>
								<p>We are looking for Business Development Executive with experience in the same domain (preferably IT).</p>
								<button onClick={showModal} className="main-button">Apply</button>
							</div>
						</li>
						<li className="job-openings-col">
							<div className="job-openings-box">
								<h3>Business Development Executive</h3>
								<p>We are looking for Business Development Executive with experience in the same domain (preferably IT).</p>
								<button onClick={showModal} className="main-button">Apply</button>
							</div>
						</li>
					</ul> */}
				 </div>
			</div>
      <Modal className="career-modal-area"  visible={isModalVisible}  onCancel={handleCancel} >
         <div className="career-modal-detail-form">
			 <div className="career-modal-detail">
				 <div className="business-development-detail">
				 <h2>Business Development Executive</h2>
				 <p>Full Time/Vadodara</p>
				 <p>We are looking for Business Development Executive with experience in the same domain (preferably IT).</p>
				 <p>The ideal candidate should have experience in all stages of the sales cycle. They should be confident with 
				 building new client relationship and maintaining existing ones. They should have evidence of strong skills and 
				 possess good negotiatio n skills</p>
				 </div>   
				 <div className="business-inquiry-form"> 
				   <h2 className="main-title">Inquiry Form</h2> 
				   <ul className="yellow-dot-list">
				      <li>Lead Generation and New client acquisitions. Client Meeting and Coordination</li>
					  <li>Maintaining proper follow up with the clients and converting them into strong prospects.</li>
					  <li>Proposal correspondence</li>
					  <li>Analyze market and establish competitive advantages.</li>
					  <li>Extensive experience of bidding on Upwork, Freelancer, PeoplePerHour etc.</li>
					  <li>Periodic report generation on sales activity.</li>
					  <li>Seeking client feedback.</li>
				   </ul>
				 </div>
			 </div>
			 <div className="career-modal-form">
			   <div className="form-detail-area">
									<h2 className="main-title">Inquiry Form</h2>
									<ul className="form-detail-row">
										<li className="form-detail-col">
											<div class="form-group form_box">
												<input type="text" placeholder="First Name" name="txtname" maxlength="200" minlength="2" class="form-control required" required="" />
											</div>
										</li>
										<li className="form-detail-col">
											<div class="form-group form_box">
												<input type="text" placeholder="Last Name" name="txtname" maxlength="200" minlength="2" class="form-control required" required="" />
											</div>
										</li>
										<li className="form-detail-col">
											<div class="form-group form_box">
												<input type="text" placeholder="Email Id" name="txtname" maxlength="200" minlength="2" class="form-control required" required="" />
											</div>
										</li>
										<li className="form-detail-col">
											<div class="form-group form_box">
												<input type="text" placeholder="Phone Number" name="txtname" maxlength="200" minlength="2" class="form-control required" required="" />
											</div>
										</li>
										<li className="form-detail-col full-col">
											<div class="form-group form_box">
												<textarea class="form-control form_textarea" name="txtmessage" placeholder="Comment" maxlength="500" required=""></textarea>
											</div>
											<button className="main-button">Submit</button>
										</li>
									</ul>
								</div>
			 </div>
		 </div>
      </Modal>
		</>
	);
}

export default withRouter(Career);
import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import Playicon from "../assets/images/icons/play-icon.svg";
import Fbicon from "../assets/images/icons/fb_icon.svg";
import Linkdinicon from "../assets/images/icons/linkdin_icon.svg";
import { useLocation } from 'react-router-dom';
import ROUTES from '../route/routes';

export default function Footer(props) {
     const location = useLocation()
    const pathname = location.pathname;
     useEffect(() => {
       window.scrollTo(0, 0);
      }, [pathname]);

    return (
        <>
            <div className="footer-area">
                <div className="footer-video sectionpadding sectionpadding-boottom">
                    <div className="footer-container">
                        <div className="footer-video-box">
                            <h3>Our dream is to build leading alliances with clients and offer complete customer satisfaction.  </h3>
                            <bottom className="playbutton">
                                <img src={Playicon} alt="Play icon"/>
                            </bottom>
                        </div>
                    </div>
                </div>
                <div className="footer-detail-area sectionpadding">
                    <div className="footer-container">
                        <ul className="footer-manu-row">
                             <li className={(pathname === ROUTES.aboutus.path) ? 'active' : ''}>
                                            <Link to={ROUTES.aboutus.path} >About Us</Link>
                                        </li>
                                        <li className={(pathname === ROUTES.services.path) ? 'active' : ''}>
                                            <Link to={ROUTES.services.path} >Services</Link>
                                        </li>
                                        <li className={(pathname === ROUTES.products.path) ? 'active' : ''}>
                                            <Link to={ROUTES.products.path} >Products</Link>
                                        </li>
                                        <li className={(pathname === ROUTES.client.path) ? 'active' : ''}>
                                            <Link to={ROUTES.client.path} >Clients</Link>
                                        </li>
                                        <li className={(pathname === ROUTES.career.path) ? 'active' : ''}>
                                            <Link to={ROUTES.career.path} >Career</Link>
                                        </li>
                                        <li className={(pathname === ROUTES.contactus.path) ? 'active' : ''}>
                                            <Link to={ROUTES.contactus.path} >Contact</Link>
                                        </li>
                                          <li className={(pathname === ROUTES.privacypolicy.path) ? 'active' : ''}>
                                            <Link to={ROUTES.privacypolicy.path} >Privacy Policy</Link>
                                        </li>
                        </ul>
                        <ul className="footer-detail-row">
                            <li>
                                <h3>Address</h3>
                                <p className="detai-text">OU721, Esplanade, Rasulgarh, Bhubaneswar, Odisha</p>
                            </li>
                            <li>
                                <h3>Email</h3>
                                <a href="mailto:contact@mohrisa.com" className="detai-text">contact@mohrisa.com</a>
                            </li>
                            <li>
                                <h3>Call Us</h3>
                                <a href="tel:+919556923333" className="detai-text">+91 9556923333</a>
                            </li>
                            <li>
                                <h3>Timing</h3>
                                <p className="detai-text">Mon to Sat: 9AM to 5PM</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="copyright-area">
                    <div className="footer-container">
                        <div className="copyright-row">
                            <div className="col-copyright">
                                <p>Copyright © 2021 | Mohirsa Techologies. | All Rights Reserved</p>
                            </div>
                            <div className="connect-with-us">
                                <p>Connect with Us</p>
                                <ul className="connect-icon-row">
                                    <li>
                                        <Link to="/">
                                            <img src={Fbicon} alt="Facebook"/>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/">
                                            <img src={Linkdinicon} alt="Linkdinicon"/>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
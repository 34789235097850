import React from "react";
import { withRouter , Link} from "react-router-dom";
import HelmetText from "../../components/HelmetText";
import { Row, Col } from 'antd';
import HomeBanner from "../../components/HomeBanner";
import Mission from "../../assets/images/mission.svg";
import Vission from "../../assets/images/vission.svg";
import Products01 from "../../assets/images/products01.jpg";
import Products02 from "../../assets/images/products02.jpg";
import ServiceSlider from "../../components/ServiceSlider";
import ROUTES from '../../route/routes';

function home() {

	return (
		<>
			<HelmetText title="Home" />
			<HomeBanner />
			<div className="aboutus-area right-cover-bg">
				<div className=" full-container">
					<div className="left-cover-icons  sectionpadding ">
						<Row>
							<Col lg={10}>
								<div className="aboutusdetail">
									<h2 className="main-title">About Us</h2>
									<h4 className="headlinesfonts">We are a new age enterprise with the aim to bring forth cutting-edge technology products and services to our clients in every sector.</h4>
									<p>Mohrisa Technologies believe in continuous evolvement to be the catalyst of creative change.</p>
								</div>
							</Col>
							<Col lg={14}>
								<div className="mission-vision-box">
									<span className="mission-vision-icon">
										<img src={Mission} alt="Mission Icon" />
									</span>
									<h3>Mission</h3>
									<p>We believe in connecting and collaborating with businesses to bring forth their ultimate potential forward by adopting a “cost-effective and maximum secure technology” platform.</p>
								</div>
								<div className="mission-vision-box">
									<span className="mission-vision-icon">
										<img src={Vission} alt="Vission Icon" />
									</span>
									<h3>Vision</h3>
									<p>Our dream is to build leading alliances with clients and offer complete customer satisfaction</p>
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</div>
			<div className="our-services-area sectionpadding sectionpadding-boottom">
				<div className="full-container">
					<Row>
						<Col md={24} lg={10}>
							<h2 className="main-title">Our Services</h2>
						</Col>
						<Col md={24} lg={14}>
							<p>An optimal solution for businesses on a global level - SAP. To cater to the ever-changing regulatory norms and offer dynamic solutions to plan, design and execute your SAP’s security and authorization system.</p>
						</Col>
					</Row>
					<ServiceSlider />
				</div>
			</div>
			<div className="our-products-area sectionpadding sectionpadding-boottom">
				<div className="full-container">
					<Row align="middle">
						<Col md={24} lg={10}>
							<h2 className="main-title">Our Products</h2>
						</Col>
						<Col md={24} lg={14}>
							<p>Experience the new form of shopping with ease and comfort with ZapQ. Add your supermarket store on App and reap amazing benefits.</p>
						</Col>
					</Row>
					<Row>
						<Col sm={12} lg={12}>
							<div className="products-boxs">
								<span className="product-img">
									<img alt="ZAPQ Swift shopping" src={Products01} />
								</span>
								<h3>ZAPQ Swift shopping</h3>
								<p>Experience the new form of shopping with ease and comfort with ZapQ. Add your supermarket store on App and reap amazing benefits. </p>
							    <Link to={ROUTES.zapq.path} class="main-button">Explore More</Link>
							</div>
						</Col>
						<Col sm={12} lg={12}>
							<div className="products-boxs">
								<span className="product-img">
									<img alt="My Kommunity" src={Products02} />
								</span>
								<h3>My Kommunity</h3>
								<p>We offer state-of-the-art technology to keep your routine life more comfortable, safe and secure.</p>
								<Link to={ROUTES.products.path} class="main-button">Explore More</Link>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
}

export default withRouter(home);
import React from "react";
import { withRouter } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import RenderRoutes from '../../route/renderRoutes';
import Services from "../subpages/Services";


function DashboardLayout() {
  return (
    <div>
      <Header />
      <Services/>
       <RenderRoutes layout="home"/> 
      <Footer/>
    </div>    
  );
}

export default withRouter(DashboardLayout);
import {
  SET_MESSAGE,
  CLEAR_MESSAGE
} from "../actions/types";

const initialState = {
    message: '',
    messageType: '',
};

var alertReducer = (state = initialState, action) => {
  const { type, payload } = action;
  
  switch (type) {
    case SET_MESSAGE:
      return { message: payload.message, messageType: payload.messageType };

    case CLEAR_MESSAGE:
      return { message: "" };

    default:
      return state;
  }
}

export default alertReducer;

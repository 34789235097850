import React from "react";
import { withRouter } from "react-router-dom";
import HelmetText from "../../components/HelmetText";
import { Row, Col } from 'antd';
import Mission from "../../assets/images/mission.svg";
import Vission from "../../assets/images/vission.svg";
import InnerBanner from "../../components/InnerBanner";


function Aboutus() {

	return (
		<>
			<HelmetText title="About Us" />
			<InnerBanner/>
			<div className="aboutus-area right-cover-bg">
				<div className=" full-container">
					<div className="left-cover-icons  sectionpadding ">
						<Row>
							<Col lg={10}>
								<div className="aboutusdetail">
									<h2 className="main-title">About Us</h2>
									<h4 className="headlinesfonts">We are a startup enterprise with the aim to bring forth cutting-edge technology 
									products and services to our clients in every sector. Mohrisa Technologies believes in continuous evolvement to be 
									the catalyst of creative change</h4>
									<p>We believe in driving change by encompassing technological, security and economic solutions. 
										These solutions play an integral role in resolving future challenges by exploring paths for digital
										 inclusivity. We allow the companies to expand their capabilities along with innovation. With customer
										  collaboration, we further provide streamlined IT solutions for smooth business functioning. We help
										   you emulate the sophistication levels of industry requirements. Alongside, we work towards maximizing
										    the potential with comprehensive services to get the job done.</p>
								</div>
							</Col>
							<Col lg={14}>
								<div className="about-small-detail">
								    <p>At Mohrisa Technologies, you can trust us to provide you with equitable growth and ethical development. 
									We aim at transforming your life towards simpler, advanced, sustainable and innovative ways for a smart new-age 
									lifestyle. In the rapidly changing world, we are here to stay and grow with you by adopting new approaches to 
									shape the digital future. With our humble beginnings, we believe in constant growth and adaptability of better 
									and safer technologies. </p>
									<p>Our main office headquarters are in Bhubaneshwar and will be soon expanding to Hyderabad and Kolkata and various cities pan India. </p>
								</div>
								<div className="mission-vision-box">
									<span className="mission-vision-icon">
										<img src={Mission} alt="Mission Icon" />
									</span>
									<h3>Mission</h3>
									<p>We believe in connecting and collaborating with businesses to bring forth their ultimate potential forward by adopting a “cost-effective and maximum secure technology” platform.</p>
								</div>
								<div className="mission-vision-box">
									<span className="mission-vision-icon">
										<img src={Vission} alt="Vission Icon" />
									</span>
									<h3>Vision</h3>
									<p>Our dream is to build leading alliances with clients and offer complete customer satisfaction</p>
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		</>
	);
}

export default withRouter(Aboutus);
import { apiPost } from '../api/apiCall';
import constants from '../config/constants';
import { SET_MESSAGE } from "../actions/types";

export const login = (obj) => (dispatch) => {
    return apiPost(obj, 'login').then(
      (data) => {
        if (data.status === 200) {
          return data;
        }
        else {
          if (data.status === 202) {
            dispatch({
              type: SET_MESSAGE,
              payload: { message: data.data.message, messageType: "error" },
            });
          }
          else {
            dispatch({
              type: SET_MESSAGE,
              payload: { message: constants.somethingWentWrong, messageType: "error" },
            });
          }
          return data;
        }
      },
      (error) => {
        const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        dispatch({
          type: SET_MESSAGE,
          payload: { message: message, messageType: "error" },
        });
  
        return Promise.reject();
      }
    );
};

export const getProfile = () => (dispatch) => {
  return apiPost({}, 'get-profile', false, 'authHeaders').then(
    (data) => {
      if (data.status === 200) {
        return data;
      }
      else {
        if (data.status === 202) {
          dispatch({
            type: SET_MESSAGE,
            payload: { message: data.data.message, messageType: "error" },
          });
        }
        return data;
      }
    },
    (error) => {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
      dispatch({
        type: SET_MESSAGE,
        payload: { message: message, messageType: "error" },
      });

      return Promise.reject();
    }
  );
};

export const logout = () => (dispatch) => {
  return apiPost({}, 'logout', false, 'authHeaders').then(
    (data) => {
      if (data.status === 200) {
        return data;
      }
      else {
        if (data.status === 202) {
          dispatch({
            type: SET_MESSAGE,
            payload: { message: data.data.message, messageType: "error" },
          });
        }
        return data;
      }
     
    },
    (error) => {
      const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
      
      dispatch({
        type: SET_MESSAGE,
        payload: { message: message, messageType: "error" },
      });

      return Promise.reject();
    }
  );
};
import React from "react";
import Slider from "react-slick";
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import Homebanner1 from '../assets/images/home_banner/homebanner1.jpg';
import Homebanner2 from '../assets/images/home_banner/homebanner2.jpg';
import Homebanner3 from '../assets/images/home_banner/homebanner3.jpg';
import ROUTES from '../route/routes';

export default function HomeBanner(props) {
    var settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        fade: true,
        nextArrow: < ArrowDownOutlined />,
        prevArrow: <ArrowUpOutlined />
    };
    return (
        <div className="home-banner-area right-cover-bg">
            <div className="full-container">
                <Slider {...settings} className="home-banner-slider">
                    <div>
                        <div className="home-banner-box">
                            <img alt="Banner" src={Homebanner1} />
                            <div className="home-banner-text">
                                <h1>Transformed </h1>
                                <h2>
                                    <span>Innovative  </span> Solutions
                                </h2>
                                <Link to={ROUTES.services.path} className="main-button">Explore More</Link>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="home-banner-box">
                            <img alt="Banner" src={Homebanner2} />
                            <div className="home-banner-text">
                                <h1>SAP </h1>
                                <h2>
                                    <span>Security Authorization</span><br/> Role Redesign
                                </h2>
                                <Link to={ROUTES.services.path} className="main-button">Explore More</Link>
                            </div>
                        </div>
                    </div>
                     <div>
                        <div className="home-banner-box">
                            <img alt="Banner" src={Homebanner3} />
                            <div className="home-banner-text">
                                <h1>Empowering </h1>
                                <h2>
                                    Your <span>Business to success</span> 
                                </h2>
                                <Link to={ROUTES.services.path} className="main-button">Explore More</Link>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
        </div>
    );
}
import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import Services01 from '../assets/images/services_box/services_01.jpg';
import Services02 from '../assets/images/services_box/services_02.jpg';
import Services03 from '../assets/images/services_box/services_03.jpg';
import Services04 from '../assets/images/services_box/services_04.jpg';
import ROUTES from '../route/routes';

export default function ServiceSlider(props) {
    var services = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <ArrowRightOutlined />,
        prevArrow: <ArrowLeftOutlined />,
        responsive: [
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },
            {
              breakpoint: 621,
              settings: {
                slidesToShow: 1,
                slidesToScroll:1,
                initialSlide: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };
    return (
        <Slider {...services} className="service-slider-slider">
            <div>
                <div className="service-slider-box">
                    <img className="service-img" alt="Service" src={Services01} />
                    <div className="main-services-box">
                        <h3>SAP Security Build</h3>
                        <p>We aim to achieve a creative SAP Security design. </p>
                        <Link to={ROUTES.services.path} className="main-button">Explore More</Link>
                    </div>
                </div>
            </div>
            <div>
                <div className="service-slider-box">
                    <img className="service-img" alt="Service" src={Services02} />
                    <div className="main-services-box">
                        <h3>SAP Security Redesign</h3>
                        <p>Our experts do a complete and thorough check of the existing authorisation structure</p>
                        <Link to={ROUTES.services.path} className="main-button">Explore More</Link>
                    </div>
                </div>
            </div>
            <div>
                <div className="service-slider-box">
                    <img className="service-img" alt="Service" src={Services03} />
                    <div className="main-services-box">
                        <h3>SAP Upgradations & Migrations</h3>
                        <p>The integrated SAP up-gradation and migration services will safeguard your security</p>
                        <Link to={ROUTES.services.path} className="main-button">Explore More</Link>
                    </div>
                </div>
            </div>
            <div>
                <div className="service-slider-box">
                    <img className="service-img" alt="Service" src={Services04} />
                    <div className="main-services-box">
                        <h3>SAP Security Managed Services</h3>
                        <p>Consider us to be your trusted SAP security partner for your business.  </p>
                        <Link to={ROUTES.services.path} className="main-button">Explore More</Link>
                    </div>
                </div>
            </div>
        </Slider>
    );
}
import React from "react";
import { withRouter } from "react-router-dom";
import HelmetText from "../../components/HelmetText";
import client01 from "../../assets/images/client/client01.jpg";
import client02 from "../../assets/images/client/client02.jpg";
import client03 from "../../assets/images/client/client03.jpg";
import InnerBanner from "../../components/InnerBanner";

function Client() {

	return (
		<>
			<HelmetText title="Client" />
			<InnerBanner/>
			<div className="sectionpadding common-section ">
				<div className=" full-container">
					<ul className="client-row">
					  <li className="client-col">
					    <div  className="client-box">
						   <img src={client01} alt="Client 01"/>
						</div> 
					  </li>
					  <li className="client-col">
					    <div  className="client-box">
						   <img src={client02} alt="Client 02"/>
						</div> 
					  </li>
					  <li className="client-col">
					    <div  className="client-box">
						   <img src={client03} alt="Client 03"/>
						</div> 
					  </li>
					</ul>
				</div>
			</div>
		</>
	);
}

export default withRouter(Client);
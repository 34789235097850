import React,{useState, useEffect} from "react";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import {Row, Col, Menu, Dropdown, Button } from 'antd';
import { useLocation } from 'react-router-dom';
import ROUTES from '../route/routes';

export default function Header() {
    const location = useLocation()
    const pathname = location.pathname;
    const [isActiveMenu, setISActiveMenu] = useState(false);
    const Openmenu = () =>{
        setISActiveMenu(true)
    }
    const Closemenu = () =>{
        setISActiveMenu(false)
    }
     useEffect(() => {
       window.scrollTo(0, 0);
       setISActiveMenu(false);
      }, [pathname]);

     const menu = (
    <Menu className="main-dropdown">
     <Menu.Item className={(pathname === ROUTES.products.path) ? 'active' : ''}>
        <Link to={ROUTES.products.path} >My Kommunity</Link>
    </Menu.Item>
    <Menu.Item className={(pathname === ROUTES.zapq.path) ? 'active' : ''}>
        <Link to={ROUTES.zapq.path} >ZAPQ Swift shopping</Link>
    </Menu.Item>
  </Menu>
);
    return (
        <>
            <div className="header-wrapper right-cover-bg">
                <div className="full-container">
                    <Row>
                        <Col span={12}>
                            <Logo/>
                        </Col>
                        <Col span={12}>
                            <div className='header-menu-area'>
                                <button className="mobile-btn main-burger-button" onClick={Openmenu}>
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </button>
                                <div className={'header-mobile-screen ' + ( isActiveMenu ? 'active' : '')}>
                                    <button className="mobile-btn close" onClick={Closemenu}> X </button>
                                    <ul className="header-row" >
                                        <li className={(pathname === ROUTES.aboutus.path) ? 'active' : ''}>
                                            <Link to={ROUTES.aboutus.path} >About Us</Link>
                                        </li>
                                        <li className={(pathname === ROUTES.services.path) ? 'active' : ''}>
                                            <Link to={ROUTES.services.path} >Services</Link>
                                        </li>
                                        <li className={(pathname === ROUTES.products.path || pathname === ROUTES.zapq.path) ? 'active' : ''}>
                                            <Dropdown overlay={menu} placement="bottomLeft" arrow>
                                                <Button>Products</Button>
                                            </Dropdown>
                                        </li>
                                        <li className={(pathname === ROUTES.client.path) ? 'active' : ''}>
                                            <Link to={ROUTES.client.path} >Clients</Link>
                                        </li>
                                        <li className={(pathname === ROUTES.career.path) ? 'active' : ''}>
                                            <Link to={ROUTES.career.path} >Career</Link>
                                        </li>
                                        <li className={(pathname === ROUTES.contactus.path) ? 'active' : ''}>
                                            <Link to={ROUTES.contactus.path} >Contact</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
}






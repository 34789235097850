import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import HelmetText from "../../components/HelmetText";
import { Row, Col } from 'antd';
import { useTranslation } from "react-i18next";
import TitleText from "../../components/TitleText";
import ButtonBlue from "../../components/ButtonBlue";
import { logout } from "../../services/auth.service";

import { SET_MESSAGE, SET_USER } from "../../actions/types";
import ROUTES from "../../route/routes";

function Dashboard() {

	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const membership = useSelector(state => state.membership);
	const dispatch = useDispatch();
	const history = useHistory();
	
	useEffect(() => {
		dispatch({
			type: SET_MESSAGE,
			payload: { message: '', messageType: "" },
		});
	}, []);

	const handleLogout = () => {
		setLoading( true );  

        dispatch(logout())
        .then((obj) => {
			setLoading( false ); 
			
            localStorage.removeItem("accessToken");
				
			dispatch({
				type: SET_USER,
				payload: { user: {} },
			});
			
			if (obj.status === 200) {
				dispatch({
					type: SET_MESSAGE,
					payload: { message: obj.data.message, messageType: "success" },
				});
			}
			else if (obj.status === 203) {

				dispatch({
					type: SET_MESSAGE,
					payload: { message: obj.data.message, messageType: "error" },
				});

			}

			history.push({
				pathname: ROUTES.login.path,
			});
        })
        .catch((error) => {
            setLoading( false );  
            dispatch({
                type: SET_MESSAGE,
                payload: { message: error, messageType: "error" },
            });

        });
	}

	return (
		<div className="main-wrapper">
			<HelmetText title="Dashboard"/>
			{<Row>
				<Col>
					<TitleText level={2} text= {t("logged_in").replace('[USERNAME]', membership.name)} />
					<ButtonBlue type="primary" text={t("logout")} loading = {loading} onClick={ handleLogout }/>
				</Col>
			</Row>}
		</div>
	);

}

export default withRouter(Dashboard);
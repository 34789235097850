import axios from 'axios';

export const api = axios.create({
   baseURL: process.env.REACT_APP_USER_API_BASE_URL,
   timeout: 50000,
   maxBodyLength: Infinity,
});

export const headers =  { 
   headers:{
      ApiKey: process.env.REACT_APP_USER_API_KEY,
      Accept: 'application/json'
   }  
};
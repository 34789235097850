import React from "react";
import { withRouter } from "react-router-dom";
import HelmetText from "../../components/HelmetText";
import { Row, Col } from 'antd';
import Zpaq from "../../assets/images/product2/zpaq.jpg";

import InnerBanner from "../../components/InnerBanner";


function Zapq() {

	return (
		<>
			<HelmetText title="ZAPQ Swift shopping" />
			<InnerBanner/>
			<div className=" common-section right-cover-bg">
				<div className=" full-container">
					<div className="left-cover-icons  sectionpadding ">
						<Row>
							<Col lg={11}>
								<div className="common-section-left">
									<h2 className="main-sub-title">ZAPQ Swift shopping</h2>
									<h4 className="headlinesfonts">Experience the new form of shopping with ease and comfort with ZapQ. Add your supermarket store on App and reap amazing benefits.  </h4>
									<h2 className="main-sub-title">Key Benefits</h2>
									<ul class="dotlist">
									    <li>Let shoppers easily discover your supermarket store</li>
									    <li>Get a list of the shopper’s requirements</li>
									    <li>Gather and pack the order</li>
									    <li>Easy options for pick-up or delivery</li>
									</ul>
								</div>
							</Col>
							<Col lg={13}>
								<div className="common-section-right">
									<div className="common-img">
									<img src={Zpaq} alt="Mykommunity"/>
								</div>
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		</>
	);
}

export default withRouter(Zapq);
import React,{ Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import 'antd/dist/antd.css'; 
import "../src/app/assets/css/app.css";
import "../src/app/assets/css/style.scss";
import '../src/app/config/i18n';
import {Provider} from "react-redux";
import store from "./store";

ReactDOM.render(
    <Provider store={store}> 
      <BrowserRouter>
        <Suspense fallback="loading"><App /></Suspense>   
      </BrowserRouter>
    </Provider> ,
  document.getElementById('root')
);

import React from "react";
import {Helmet} from "react-helmet";

export default function HelmetText(props) {
    return (
        <Helmet>
            {/* <title>{ `${props.title} | ${process.env.REACT_APP_NAME}` }  </title> */}
            <title>{ `${props.title} | Mohrisa Technologies` }  </title>
        </Helmet>
    );
}
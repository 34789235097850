import React from 'react';
import { Route, Switch } from 'react-router-dom';
import DefaultLayout from './app/view/master-layout/DefaultLayout';
import DashboardLayout from './app/view/master-layout/DashboardLayout';
// import NotFound from '../views/NotFound';

function App() {
  return (
    <Switch>    
      <Route path="/user" render={props => <DashboardLayout {...props} />} />   
      <Route  render={props => <DefaultLayout {...props} />} />     
    </Switch>
  );
}

export default App;
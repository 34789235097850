import React from "react";
import { withRouter } from "react-router-dom";
import HelmetText from "../../components/HelmetText";
import InnerBanner from "../../components/InnerBanner";

function Privacypolicy() {

	return (
		<>
			<HelmetText title="Privacy Policy" />
			<InnerBanner />
			<div className="privacy-policy-page sectionpadding common-section ">
				<div className=" full-container">
					<div className="privacy-policy-privacy-policy-common-box">
						<h2 className="main-title">Privacy Policy</h2>
						<h4 className="headlinesfonts">
							When you use Mohrisa Technologies Pvt Ltd’s services, either via website or the App, you are trusting us with some of your information.</h4>
						<p> We understand this is a big responsibility on our shoulders and we are always working hard to keep your information safe and secure, and give you control over your data.. This Privacy Policy section is meant to help you understand what information Mohrisa Technologies Pvt Ltd collects, why we collect such information and how you have full control to update, manage or delete your information.</p>
						<ul class="dotlist">
							<li>Introduction</li>
							<li>Information Mohrisa Technologies Pvt Ltd Collects</li>
							<li>How the Information is collected</li>
							<li>Why information is collected</li>
							<li>User Privacy controls</li>
							<li>Sharing Your Information and Disclosures</li>
							<li>Your Information Security</li>
							<li>Your Information Retention and Deletion</li>
							<li>Role and details of Data Protection Officer</li>
						</ul>
					</div>
					<div className="privacy-policy-common-box">
						<h3 className="main-sub-title">Introduction</h3>
						<p>Mohrisa Technologies Pvt Ltd is a comprehensive security and community management platform helping millions of users to make their life safe, secure and convenient. Our various services include:</p>
					</div>
					<div className="privacy-policy-common-box">
						<h3 className="main-sub-title"> Accounts & Payments Management:</h3>
						<p>Mohrisa Technologies Pvt Ltd features an end-to-end accounting module to simplify management of finances for Resident Welfare Associations. Residents can also pay their maintenance bills via the app.</p>
					</div>
					<div className="privacy-policy-common-box">
						<h3 className="main-sub-title">Visitor Management: </h3>
						<p>Mohrisa Technologies Pvt Ltd’s technology enhances security by ensuring that only approved visitors are given entry to the premises.</p>
					</div>
					<div className="privacy-policy-common-box">
						<h3 className="main-sub-title">Delivery Management:</h3>
						<p>With Mohrisa Technologies Pvt Ltd, users can have their deliveries left at the gate, in case they are not available at home – and pick them up at their convenience. Mohrisa Technologies Pvt Ltd has also partnered with eCommerce portals to make the entry of delivery personnel faster and more secure.</p>
					</div>
					<div className="privacy-policy-common-box">
						<h3 className="main-sub-title">Security Alert:</h3>
						<p> In case of an emergency, residents can alert their security personnel at the click of a button on the app.</p>
					</div>
					<div className="privacy-policy-common-box">
						<h3 className="main-sub-title">Child Security:</h3>
						<p>Resident parents can require their security guards to check whether their children have their permission to exit the premises (This feature doesn’t require any details of children to be collected).</p>
					</div>
					<div className="privacy-policy-common-box">
						<h3 className="main-sub-title">Daily Help Management:</h3>
						<p>All residents receive notifications on the entry of their daily help (maids, cooks, nannies), and can also check their monthly attendance. Residents can also pay their daily help’s salary via the app or record a cash payment.</p>
					</div>
					<div className="privacy-policy-common-box">
						<h3 className="main-sub-title">Clubhouse Management & Amenities’ Booking:</h3>
						<p>The Management Committee can use this feature to ensure that the clubhouse area is restricted to residents and their guests, understand usage trends, and even implement a booking feature for certain facilities.</p>
					</div>

					<div className="privacy-policy-common-box">
						<h3 className="main-sub-title">Helpdesk and Communication: </h3>
						<p>Residents can also have their complaints resolved by raising an issue to the concerned person via their app.</p>
						<p>Mohrisa Technologies Pvt Ltd seamlessly connects the community – the residents, RWA, facility management, security personnel, daily help and visitors – through the app, with a view to improving the security and convenience of the premises.</p>
						<p>Apart from using our services, all users can use our Web and App in a variety of ways to manage their data privacy.</p>
					</div>
					<div className="privacy-policy-common-box">
						<h2 className="main-title">Information Mohrisa Technologies Pvt Ltd Collects</h2>
						<p>We would like you to understand that the information collected via the Mohrisa Technologies Pvt Ltd app or website is to help us provide you with superlative service.</p>
						<p>The information Mohrisa Technologies Pvt Ltd collects, and how that information is used, depends on how you use our services and how you manage your privacy controls.
							For e.g. for standard Mohrisa Technologies Pvt Ltd services, only your flat details along with name and number will be needed. However, if your society chooses to use advanced features
							like Accounts and payments, additional information may be needed to render services. All the personally identifiable information is treated as sensitive information by us and you will have
							full control over this data.</p>
						<p>Information collected on the Mohrisa Technologies Pvt Ltd app and website:</p>
					</div>
					<div className="privacy-policy-common-box">
						<h3 className="main-sub-title">Residents:</h3>
						<p>Name, Email address, Mobile number, your apartment/villa number, vehicle number (if any) and any other data you may have chosen to provide on Mohrisa Technologies Pvt Ltd</p>
					</div>
					<div className="privacy-policy-common-box">
						<h3 className="main-sub-title">Guests:</h3>
						<p>Name and resident flat to be visited. No other guest information is required on Mohrisa Technologies Pvt Ltd App for entry at gate (Note: Individual RWAs may mandate additional information such as phone number which is totally at discretion of respective RWAs.)</p>
					</div>
					<div className="privacy-policy-common-box">
						<h3 className="main-sub-title">Service Providers to Society/Individual Homes: </h3>
						<p>The name, phone number, resident flat to be visited (if any), vehicle number (if any), entry/exit time, visit purpose and photograph.
							For security reasons, some personally identifiable information of service providers including delivery executives is taken at the gate as per appropriate local laws. Mohrisa Technologies Pvt Ltd processes the information as per service requirements.</p>
					</div>
					<div className="privacy-policy-common-box">
						<h3 className="main-sub-title">Additional information: </h3>
						<p>IP address, Webpages visited (Pages of Mohrisa Technologies Pvt Ltd.com visited by the user; a standard practise to optimize user experience and
							curate content of a website), browser, device and operating system information, mobile network information, the date, time, and referrer URL of your
							request (the website from which the user was redirected to Mohrisa Technologies Pvt Ltd.com; a standard practise to track and compensate the originator
							of traffic to Mohrisa Technologies Pvt Ltd website, such as when a visitor clicks on a Google ad for Mohrisa Technologies Pvt Ltd.)</p>
					</div>
					<div className="privacy-policy-common-box">
						<h3 className="main-sub-title">Information Collected from Other Sources / Third Parties:</h3>
						<p>In addition to the above, in light of the COVID Pandemic, Resident, Guest and/or Service Providers to the Society / Individual homes shall have the option
							to use the Aarogya Setu Mobile App (Aarogya Setu App) to initiate the permission to gain entry into the respective flat / society. If a Resident, Guest and/or
							Service Providers to the Society / Individual homes use the Aarogya Setu App to gain entry, Mohrisa Technologies Pvt Ltd shall receive the following information
							from the Aarogya Setu App upon scanning of the QR Code:</p>
						<ul>
							<li>Name</li>
							<li>Mobile Number</li>
							<li>Risk Category</li>
						</ul>
						<p>Individual RWAs may mandate the use of the Aarogya Setu App for gaining entry into the RWA which is totally and solely at the discretion of respective RWAs.</p>
						<p>We collect standard website access information (type of browser etc) using cookies and other similar technologies on all our websites
							<a href="">(Mohrisa Technologies Pvt Ltd.com , mohrisa.com).</a>
							We do this to measure, improve and personalize our services for you and we do not share this information with any third party organizations for commercial purposes.
						</p>
						<p>We don’t sell or trade user information in any form to anyone. The information is used solely for the purpose of enabling services and making your experience better
							on Mohrisa Technologies Pvt Ltd platforms. On a case to case basis we give you great value add from third party partners where information will be shared after your explicit consent.</p>
						<p>Should you wish to know more about our policy on guest visitors’ data, click here</p>
						<p>Mohrisa Technologies Pvt Ltd’s services are an extension of the services offered by the Resident Welfare Association (RWA) (being our direct customer) of
							the premises. The RWA members will have access to all the information collected for their society on Mohrisa Technologies Pvt Ltd for recording and security purposes.
							In case any of the RWA members access any of resident data, Mohrisa Technologies Pvt Ltd will transparently convey the same to the user along with the reason for access
							of the information.</p>
					</div>
					<div className="privacy-policy-common-box">
						<h2 className="main-title">How The Information is Collected</h2>
						<p>Mohrisa Technologies Pvt Ltd provides its services through our website and mobile app (will be collectively referred to as “Platforms”). User information is collected via our platforms either directly or indirectly to give great product and service experience.</p>
						<h3 className="main-sub-title">Direct Information:</h3>
						<p>All personally identifiable information as mentioned in “Information Mohrisa Technologies Pvt Ltd Collects” segment is explicitly collected on the platform where users fill in their details and give necessary consent to be contacted or their information to be used by Mohrisa Technologies Pvt Ltd for serving them better via the platforms.</p>
					</div>
					<div className="privacy-policy-common-box">
						<h3 className="main-sub-title">Indirect Information:	</h3>
						<p>Information like Ip address, device data, browsing data and other information as detailed in “Information Mohrisa Technologies Pvt Ltd Collects” is collected while the user is interacting with our platform. The same is collected to make user experience on our platforms better</p>
					</div>
					<div className="privacy-policy-common-box">
						<h3 className="main-title">Why The Information is Collected</h3>
						<p>We use information collected from users to provide our services. The data is collected on Mohrisa Technologies Pvt Ltd  website as per discretion and inputs given by users. On the mobile app information is collected as per our agreement with RWA of societies who act as an extended service provider to all residents within the society.</p>
						<p>Following are the key services which will require information collected</p>
						<p>Account set up and administration</p>
						<p>Personalization of content, business information or user information</p>
						<p>Periodically communicate with you (like new feature/benefits introduction, feature usage guidance etc)</p>
						<p>Data recording and analysis on platforms usage to improve future services (All analysis are done only at aggregate level and Mohrisa Technologies Pvt Ltd never accesses or analyse data at user level)</p>
						<p>Legal obligations and meeting internal audit requirements (Non-guest visitor information)</p>
						<p>Protect Our Users, Mohrisa Technologies Pvt Ltd: Information is used to improve safety and reliability of services across platforms. This include detecting, preventing and responding to security risks, fraud or any technical issues which can harm our users or Mohrisa Technologies Pvt Ltd</p>

					</div>
					<div className="privacy-policy-common-box">
						<h3 className="main-title">User Privacy Controls</h3>
						<p>Mohrisa Technologies Pvt Ltd ensures that you are in full control of the information we collect and how it is used.</p>
						<p>This section describes key controls for managing your privacy across our platforms and services. In addition to this we also provide few other mechanisms by which users can reach out to us to modify or erase their information available with us.</p>
					</div>
					<div className="privacy-policy-common-box">
						<h3 className="main-sub-title">Managing, reviewing, and updating your information:</h3>
						<p>When you’re signed in, you can always review and update information by visiting the services you use. For example, you can change your contact details such as your name, email and phone number.</p>
					</div>
					<div className="privacy-policy-common-box">
						<h3 className="main-sub-title">Choice to Opt-out:</h3>
						<p>We provide all users with options to opt-out from receiving non-essential (promotional, marketing related etc.) communications from us. This can be done directly on our platforms or providing us with necessary information at <a href="mailto:admin@mohrisa.com" class="detai-text">admin@mohrisa.com</a></p>
					</div>
					<div className="privacy-policy-common-box">
						<h3 className="main-sub-title">Unsubscribing, removing and deleting your information:</h3>
						<p>If you choose to unsubscribe from our platforms or delete any of all of your information, you can delete your apartment and then uninstall the app. In addition to this you may also send an email to
							<a href="mailto:admin@mohrisa.com" class="detai-text">admin@mohrisa.com</a>  for specific information about your account or deletion of your account history. However, we may still retain some information
							and records of transaction for specific period as required by any law, contract with RWA or policy as applicable.
						</p>
					</div>
					<div className="privacy-policy-common-box">
						<h3 className="main-sub-title">Contacting Data Protection Officer:</h3>
						<p>In subsequent sections covering “Role and details of Data Protection Officer(DPO)”, we have full disclosure of how users can reach out to Mohrisa Technologies Pvt Ltd DPO to lodge complaints or communicate any privacy related grievances.</p>
					</div>
					<div className="privacy-policy-common-box">
						<h3 className="main-title">Sharing Your Information and Disclosures</h3>
						<p>We do not share your personal information with companies, organizations, or individuals outside of Mohrisa Technologies Pvt Ltd or anyone within Mohrisa Technologies Pvt Ltd.</p>
						<p>There may be few exceptions:</p>
						<p>When user reaches out to Mohrisa Technologies Pvt Ltd customer care for specific query/issues and customer care personnel has to access your information for resolution of your query</p>
						<p>Explicit user consent will be taken by Mohrisa Technologies Pvt Ltd in case we partner with companies, organizations, or individuals outside of Mohrisa Technologies
							Pvt Ltd to provide services or offerings to users. The use of such services may require you to share your name, email, phone number, flat details or other information
							with these partners. In such cases,<b> Mohrisa Technologies Pvt Ltd will only share your information and offer you the service with your explicit opt-in</b></p>
						<p>For Legal Reasons: We may need to share or disclose some of your information outside Mohrisa Technologies Pvt Ltd if we have a good-faith belief that access, use, preservation or disclosure of the information is reasonable necessary in the following events:</p>
					</div>
					<div className="privacy-policy-common-box">
						<p>Meet any law, regulation or legal process as applicable or enforceable request from government.</p>
						<p>To enforce applicable terms of service, including investigation of potential violations, fraud, security or technical issues/breaches.</p>
						<p>Information as necessary with your respective societies’ RWA as per terms of agreement.</p>
						<p>Protect against harm to the rights, property or safety of our users, Mohrisa Technologies Pvt Ltd, or the public as required or permitted by Law.</p>
						<p>Consent: In some cases, we may process some of your information based on the consent you expressly grant to us at the time we collected such data. In case we need to process any of your information we will be clearly indicating to you at the point and time of collection.</p>
						<p>We have enabled societies to collect explicit consent from service providers while capturing their data. During the onboard process of any service provider, they will be getting an SMS with a phone number on which they can give a missed call to give consent for their data capture.</p>
						<p>You have right to withdraw your consent at any given point of time either via platform or writing to us at <a href="">admin@mohrisa.com.</a></p>
						<p>We do not share your information with companies, organizations or individuals. However, if any case in future arises where some data needs to be shared, we will keep you informed and take your consent as applicable.</p>
						<p>Your information will be shared with another business entity should we(or part of our business/assets) plan to merge with, or be acquired by another business entity or in cases of organization restructuring or amalgamation. In case of such a transaction or re-organization the new business entity will follow this Privacy Policy. All the affected users will be notified before personal information is transferred or becomes subject to different entities’ privacy policy.</p>
					</div>
					<div className="privacy-policy-common-box">
						<h3 className="main-title">Your Information Security</h3>
						<p>We build security into each and every system and services to protect your information.</p>
						<p>All Mohrisa Technologies Pvt Ltd platforms and processes are built with strong security features that continuously protect your information. We have multiple real time checks and tests running to help us detect and automatically block security threats from ever reaching you. In case of any detection of threat or risk that we deem you should be aware of; we will notify you and guide you through steps to keep your privacy protected.</p>
						<p>We are constantly striving to protect your data from unauthorized access, disclosure, modifications, or destruction of any information we hold, including:</p>
						<p>All information going in and out of the app is 256 bit https encrypted. TLS encryption is used throughout the application for data in motion and data is also encrypted at rest.</p>
						<p>All information is stored in servers with best in class firewalls and needs multiple authentications for any access and no unauthorized person will have access to the same.</p>
						<p>We constantly review our information collection, storage and processing practices, including physical security measures, to prevent unauthorized access to our systems.</p>
						<p>We restrict access to personal information to employees, and external contractors who may need the information in order to process some services. Any internal employee with this access is subject to strict contractual confidentiality obligations and strict actions (up to termination or criminal action) will be taken if they fail to meet these obligations.</p>
						<p>The Mohrisa Technologies Pvt Ltd app is audited on a regular basis. Mohrisa Technologies Pvt Ltd application and data servers do not use public accessible addresses or IP. The access is further secured through a VPN tunnel with 2FA (two factor authentication). All traffic is routed through a WAF (Web Application Firewall) and is checked strictly for malicious traffic.</p>
						<p>However, we would like you to understand and accept that data transmission over the internet may be liable to risk and we would assume no liability for any disclosure of information due to errors in transmission issues or unauthorized third-party access to our platforms or databases.</p>

					</div>
					<div className="privacy-policy-common-box">
						<h3 className="main-title">Your Information Retention and Deletion</h3>
						<p>The information collected on our platforms is retained for different periods of time depending on what the information is and how it is used and how you configure your settings.</p>
						<p>Users can request to export a copy of their information or delete it from your Mohrisa Technologies Pvt Ltd account at any given point of time (some information deletion may be subject to RWA approval, Mohrisa Technologies Pvt Ltd will facilitate the approval process):</p>
						<p>All the visitors’ information is retained for a specific period of time (period beyond which the data is deemed not to be reasonably necessary for operation or by law) and this interval will be as per the discretion of the societies’ RWA. Mohrisa Technologies Pvt Ltd will delete all visitors’ information from its systems after 180 days by default. The RWA will have the option to reset this to 60 days, 120 days or 180 days, but no longer. Once deleted, even Mohrisa Technologies Pvt Ltd will not be able to retrieve past data.</p>
						<p>Residents may request at any time that their data on Mohrisa Technologies Pvt Ltd systems be erased. This is referred to as the Right to be Forgotten and can be triggered from the Mohrisa Technologies Pvt Ltd app or by writing to <a href="">admin@mohrisa.com.</a></p>
						<p>Guest visitors can get their details deleted at any given point of time. Details for same is covered in “Role and details of Data Protection Officer”. We keep some data until your society is live on Mohrisa Technologies Pvt Ltd and all such information is deleted if your society is no longer live on our platform.</p>
						<p>In case you have left a society on Mohrisa Technologies Pvt Ltd, you can delete all your details on our App and uninstall our app. In case of more assistance you can write to <a href=""> admin@mohrisa.com.</a></p>
						<p>However, some data we may retain for longer period of time when necessary for legal purposes, such as security, fraud or financial record keeping.
							When you delete data or request for deletion of data, we follow a process to make sure that all your data is safely and completely removed from all our servers. We also ensure that none of your information is subject to accidental or malicious deletion. This may lead to some delay between actual requests made and final deletion as we ensure that information deletion is genuine and not accidental from our active and backup systems. Any request to DPO will be replied within 7 days and actioned within 30 days subject to approvals from the RWA and not including the time taken by them in approvals.</p>

					</div>
					<div className="privacy-policy-common-box">
						<h3 className="main-title">Role and details of Data Protection Officer (DPO)</h3>
						<p>Mohrisa Technologies Pvt Ltd has employed a dedicated data protection and grievance officer who will be responsible for overseeing the company’s data protection strategy and its implementation to ensure compliance with various Privacy law requirements.</p>
						<p>Our DPO is responsible for the following:</p>
						<p>Constantly educating the company and employees on important Data Privacy compliances</p>
						<p>Training all staff involved in data processing</p>
						<p>Conduct regular audits to ensure compliance and address any gap or issues proactively</p>
						<p>Monitoring implementation and effectiveness of data protection efforts within company</p>
						<p>Maintaining comprehensive records of all data processing activities, including purposes and necessity of all processing activities, which must be produced on request.</p>
						<p>Interfacing with data subjects to address how their information is being used, their right to have their personal data amended or erased and what measures Mohrisa Technologies Pvt Ltd has put in place to protect your personal information.</p>
						<p><i>If you have queries regarding your data and privacy, please refer to the FAQs listed here</i></p>
						<p>In case of any issue or grievance related to your data privacy, please contact our DPO at <a href="">admin@mohrisa.com</a> or write to Data Protection Office,
							Mohrisa Technologies Pvt Ltd (Mohrisa Technologies Pvt Ltd),</p>
						<p> 27, Gatikrushna Green , Rangabazar , Alarpur ,<br />
							Bhubaneswar , Odisha ,<br />
							Pin - 752100 <br />
						</p>
					</div>
				</div>
			</div>
		</>
	);
}

export default withRouter(Privacypolicy);